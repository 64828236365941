import { auth } from "./auth";
import { users } from "./users";
import { trainings } from "./trainings";
import { groups } from "./groups";
import { sessions } from "./sessions";
import { objectives } from "./objectives";
import { objectivelevels } from "./objectivelevels";
import { documents } from "./documents";
import { companies } from "./companies";
import { configs } from "./configs";
import { sales } from "./sales";
import { eventLog } from "./logs";
import { todos } from "./todo";
import { catalog } from "./catalog";

export const APIS = {
  auth,
  objectivelevels,
  objectives,
  sessions,
  groups,
  trainings,
  users,
  configs,
  documents,
  companies,
  sales,
  eventLog,
  todos,
  catalog,
};
