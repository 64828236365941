const URLS = {
  AUTHENTICATION: "/v3/oauth/token",
  USERS: "/v3/users",
  USER_TRAININGS: "/v3/teams/users/{id}/trainings",
  USER_DASHBOARD_TRAININGS: "/v3/teams/users/{id}/last-trainings",
  USER_GROUPS: "/v3/teams/users/{id}/groups",
  GROUP_TRAININGS: "/v3/teams/groups/{id}/trainings",
  USER_DOCUMENTS: "/v3/teams/users/{id}/documents",
  GROUP_USERS: "/v3/teams/groups/{id}/users",
  GROUP_SESSIONS: "/v3/teams/groups/{id}/sessions",
  USER_SESSIONS: "/v3/teams/users/{id}/sessions",
  OBJECTIVES: "/v3//teams/users/{id}/objectives",
  CONFIGS: "/v3/settingteams",
  OBJECTIVES_LEVELS: "/v3/teams/users/{id}/objective-levels",
  COMPANIES: "/v3/teams/users/companies",
  USERS_LAST_LOGIN_DATE: "/v3/teams/users/{id}/last-login-date",
  SALES_MAIL: "/v3/teams/sendSalesMail",
  EVENT_LOG: "/v3/teams/users/{id}/event",
  STATISTICS: "/v3/teams/users/{id}/stats",
  UPCOMING_SESSIONS: "/v3/teams/users/{id}/upcoming-sessions",
  ON_HOLD_EVALUATIONS: "/v3/teams/users/{id}/on-hold-evaluations",
  TODOS: "/v3/teams/users/{id}/todos",
  TOS: "/v3/teams/users/{id}/tos",
  CATALOG_RECOMMENDED_COURSES:
    "/v3/teams/users/{id}/catalog-recommended-courses",
  CATALOG_RECENT_COURSES: "/v3/teams/users/{id}/catalog-recent-courses",
  CATALOG_FILTERS: "/v3/teams/users/{id}/catalog-filters",
  CATALOG_TRAININGS: "/v3/teams/users/{id}/catalog-trainings",
};

export default URLS;
