import {
  formatRange,
  getCookies,
  sendAsyncRequest,
  setCookies,
} from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";
import Company from "../core/models/Company";
import Pagination from "../core/models/Pagination";

/**
 * fetch companies
 *
 * @param baseURL
 * @param page
 * @param perPage
 * @param filter
 * @param order
 * @returns
 */
const fetch = (
  page: number,
  perPage?: number,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string },
  baseURL?: string
): Promise<{ data: Company[]; pagination: Pagination }> => {
  // check if companies already saved in cookies , if it is the case then return companies from cookies
  const cookiesData = getCookies(
    `${CONSTANTS.COOKIES.COMPANIES}/${
      filter ? JSON.stringify(filter) : ""
    }/${baseURL}`
  );
  if (cookiesData) return JSON.parse(cookiesData);
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    URLS.COMPANIES,
    undefined,
    params,
    undefined,
    undefined,
    baseURL
  ).then((response) => {
    const data = {
      data: response.data,
      pagination: formatRange(response.headers["content-range"]),
    };
    // get expiration date
    const expires = new Date(
      new Date().getTime() + CONSTANTS.COOKIES_DURATION / 2
    );
    // save data in cookies
    setCookies(
      `${CONSTANTS.COOKIES.COMPANIES}/${
        filter ? JSON.stringify(filter) : ""
      }/${baseURL}`,
      expires,
      JSON.stringify(data)
    );
    return data;
  });
};

export const companies = { fetch };
