import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Loader } from "@fluentui/react-northstar";
import styles from "./Frame.module.css";
import CONSTANTS from "core/utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { APIS } from "apis";

interface FramePropsInterface {
  src: string;
  title?: string;
  className?: string;
  onClose?: () => void;
}

export const Frame = React.memo(
  ({
    src, // frame src
    title, // frame title
    className, // style class
    onClose = () => {}, // handle closing frame
  }: FramePropsInterface) => {
    // getting current user id
    const userId = useSelector((state: RootState) => state.auth.auth.id);

    // Frame Loader
    const [loading, setLoading] = useState(true);
    // handle content loaded
    const onLoad = useCallback(() => {
      setLoading(false);
    }, []);

    useEffect(() => {
      // log the open frame event
      (async () => {
        await APIS.eventLog(userId, {
          action: CONSTANTS.TEAMS_USER_EVENTS.TEAMS_OPEN_COURSE,
        });
      })();
    }, [userId]);

    // handle closing frame based on message received from the platform
    const handleCloseFrame = useCallback(
      function (e: MessageEvent<any>) {
        // checking if the message event contains the close frame event
        if (e.data === CONSTANTS.CUSTOM_EVENTS.TEAMS_APP_CLOSE_FRAME) {
          onClose();
        }
      },
      [onClose]
    );

    useEffect(() => {
      // appending message event listener
      window.addEventListener("message", handleCloseFrame);
      return () => window.removeEventListener("message", handleCloseFrame);
    }, [handleCloseFrame]);
    return (
      <Fragment>
        {loading && (
          <div className={[styles.container, className].join(" ")}>
            <Loader />
          </div>
        )}
        <iframe
          title={title}
          allowFullScreen
          src={src}
          className={[styles.frame, className].join(" ")}
          onLoad={onLoad}
        />
      </Fragment>
    );
  }
);
