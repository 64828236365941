import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

export const useGetTeamsContext = () => {
  const [hostClientType, setHostClientType] =
    useState<microsoftTeams.HostClientType>();
  useEffect(() => {
    microsoftTeams.getContext((context) => {
      setHostClientType(context.hostClientType);
    });
  }, []);

  return {
    showGuide: [
      microsoftTeams.HostClientType.desktop,
      microsoftTeams.HostClientType.web,
    ].includes(hostClientType as microsoftTeams.HostClientType),
  };
};
