import produce from "immer";
import {
  ConfigActionTypes,
  ConfigState,
  UPDATE_API_CONFIG,
  UPDATE_CONFIG,
} from "./types";

const INITIAL_STATE: ConfigState = {
  companyId: undefined,
  front: "",
  privateKey: "",
  publicKey: "",
  jwt: "",
  welcomeMessage: "",
  firstRunMessage: "",
  api_end_point: "",
};

const reducer = (
  state = INITIAL_STATE,
  action: ConfigActionTypes
): ConfigState =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_API_CONFIG:
        draft.api_end_point = action.payload.api_end_point;
        break;
      case UPDATE_CONFIG:
        draft.companyId = action.payload.companyId;
        draft.welcomeMessage = action.payload.welcomeMessage;
        draft.firstRunMessage = action.payload.firstRunMessage;
        draft.privateKey = action.payload.privateKey;
        draft.publicKey = action.payload.publicKey;
        draft.jwt = action.payload.jwt;
        draft.front = action.payload.front;
        break;
    }
  });
export default reducer;
