import React, { useEffect, useState } from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import CONSTANTS from "core/utils/constants";
import { getLocalStorage, setLocalStorage } from "core/utils/helpers";
import { StyledRoot } from "./FirstRun.style";
import { useTranslation } from "react-i18next";

export const FirstRun = React.memo(() => {
  const { t } = useTranslation();
  // get the first run message from redux
  const { firstRunMessage } = useSelector((store: RootState) => store.config);
  // get auth user name state from redux
  const authName = useSelector((store: RootState) => store.auth.auth.username);
  const [firstLand, setFirstLand] = useState(false);
  useEffect(() => {
    const firstRun = !getLocalStorage(authName + CONSTANTS.RISE_UP);
    if (firstRun) {
      setFirstLand(true);
      setLocalStorage(authName + CONSTANTS.RISE_UP, CONSTANTS.RISE_UP);
    }
    setLocalStorage(authName + CONSTANTS.RISE_UP, CONSTANTS.RISE_UP);
  }, [authName]);

  return firstLand ? (
    <StyledRoot>
      <Flex column>
        <Text
          content={`${t("On boarding message")} :`}
          weight="bold"
          size="large"
        />
        <Text content={firstRunMessage} />
      </Flex>
    </StyledRoot>
  ) : null;
});
