import { UPDATE_API_CONFIG, UPDATE_CONFIG, UpdateAPIAction } from "./types";
import { AES, enc, pad } from "crypto-js";
import CONFIGS from "../../core/utils/configs";

/**
 * update config
 *
 * @param front
 * @param publicKey
 * @param privateKey
 * @param jwt
 * @returns
 */
export const updateConfig = (
  companyId: number,
  front: string,
  publicKey: string,
  privateKey: string,
  jwt: string,
  welcomeMessage: string,
  firstRunMessage: string
) => {
  try {
    const key = enc.Hex.parse(CONFIGS.AES_KEY);
    const iv = enc.Hex.parse(CONFIGS.AES_IV);
    const jwtDecrypt = AES.decrypt(jwt, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);
    const publicKeyDecrypt = AES.decrypt(publicKey, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);
    const privateKeyDecrypt = AES.decrypt(privateKey, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);
    return {
      type: UPDATE_CONFIG,
      payload: {
        jwt: jwtDecrypt,
        companyId,
        front,
        publicKey: publicKeyDecrypt,
        privateKey: privateKeyDecrypt,
        welcomeMessage,
        firstRunMessage,
      },
    };
  } catch (error) {}
};
export const updateApiUrl = (api_end_point: string): UpdateAPIAction => ({
  type: UPDATE_API_CONFIG,
  payload: {
    api_end_point,
  },
});
