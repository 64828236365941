import React from "react";
import {
  CardHeader,
  Card,
  Flex,
  CardBody,
  CardFooter,
  Text,
  Button,
  Box,
} from "@fluentui/react-northstar";
import TrainingModel from "core/models/Training";
import { formatCourseCardDuration, getTrainingType } from "core/utils/helpers";
import { useTranslation } from "react-i18next";
import { ReactComponent as DurationIcon } from "assets/images/svg/duration.svg";
import { ReactComponent as ScoreIcon } from "assets/images/svg/score.svg";
import { ReactComponent as TimeSpentIcon } from "assets/images/svg/time_spent.svg";
import {
  AdditionalInfo,
  AdditionalInfoContainer,
  AdditionalInfoTitle,
  DataContainer,
  StepIconContainer,
} from "./Card.style";
import { StepIcon } from "components/StepIcon/StepIcon";

interface Props {
  course: TrainingModel;
  action?: string;
  handleClick: () => void;
}

export const FuiCard: React.FC<Props> = ({
  course: {
    img,
    title,
    catalogexternal,
    catalogname,
    moduletype,
    progress,
    eduduration,
    results,
    next_step,
  },
  action = "",
  handleClick,
}) => {
  const { t } = useTranslation();
  const subTitle =
    catalogexternal && catalogname
      ? catalogname
      : t(getTrainingType(moduletype));

  const duration = formatCourseCardDuration(eduduration);

  return (
    <Card>
      <CardHeader>
        <img src={img} alt={title} />
      </CardHeader>
      <CardBody>
        <Flex className="card_titles_container">
          <Text className="card card_title card_title_color">{title}</Text>
          <Text className="card card_sub_title">{subTitle}</Text>
        </Flex>
        <Flex className="progress_container">
          <Text className="progress" weight="bold" content={`${progress}%`} />
          <Box className="progress progress_bar_container">
            <Box
              className="progress progress_bar"
              style={{
                width: `${progress}%`,
              }}
            />
          </Box>
        </Flex>
        <Flex className="duration_container">
          <DurationIcon />
          <Text className="duration">{duration}</Text>
        </Flex>
        <AdditionalInfoContainer>
          {next_step && (
            <AdditionalInfoTitle>{t("next step")}</AdditionalInfoTitle>
          )}
          {results && (
            <AdditionalInfoTitle>{t("My results")}</AdditionalInfoTitle>
          )}
          <AdditionalInfo>
            {results?.totalScore && (
              <DataContainer>
                <Text>{t("Score")}</Text>
                <Text>{results.totalScore} </Text>
              </DataContainer>
            )}
            {results?.totalTrainingTime && (
              <DataContainer>
                <Text>{t("Time spent")} </Text>
                <Text>{results.totalTrainingTime} </Text>
              </DataContainer>
            )}
            {next_step && (
              <Flex vAlign="center">
                <StepIconContainer>
                  <StepIcon stepType={next_step.stepType} />
                </StepIconContainer>
                <Text align="center">{next_step.title}</Text>
              </Flex>
            )}
          </AdditionalInfo>
        </AdditionalInfoContainer>
      </CardBody>
      <CardFooter>
        <Flex className="card_footer_container">
          <Button className="footer_button" onClick={handleClick}>
            {action}
          </Button>
        </Flex>
      </CardFooter>
    </Card>
  );
};
