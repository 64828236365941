import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import * as fr from "./fr.json";
import * as en from "./en.json";
import * as de from "./de.json";

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      translation: fr,
    },
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export function strings(name: string, params = {}) {
  return i18n.t(name, params);
}
