import { APIS } from "apis";
import CONFIGS from "core/utils/configs";
import CONSTANTS from "core/utils/constants";
import { getCookies, getLocalStorage, setCookies } from "core/utils/helpers";
import { useTeams } from "msteams-react-base-component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateApiUrl } from "store/config/actions";
import { RootState } from "store/rootReducer";

const useDomainSelector = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const domain = useSelector((state: RootState) => state.config.api_end_point);
  const [{ context }] = useTeams();

  const selectDomain = (domain: string) => {
    dispatch(updateApiUrl(domain));
    const expires = new Date(
      new Date().getTime() + CONSTANTS.COOKIES_LONG_DURATION
    );
    setCookies(CONSTANTS.COOKIES.DOMAIN, expires, domain);
  };

  useEffect(() => {
    (async () => {
      // if env is not in prod then skip this step
      if (process.env.REACT_APP_ENVIRONMENT !== "prod") {
        return selectDomain(CONFIGS.API_URL);
      }
      // check if domain is already saved
      const lastDomain = getCookies(CONSTANTS.COOKIES.DOMAIN);
      if (lastDomain) {
        return selectDomain(lastDomain);
      }

      setLoading(true);

      const username = context?.loginHint ?? "";
      const token = getLocalStorage(CONSTANTS.MICROSOFT_GRAPH_TOKEN) ?? "";
      const apis = CONFIGS.API_DOMAINS.map((domain) =>
        APIS.companies.fetch(
          1,
          50,
          {
            username,
            token,
          },
          undefined,
          domain
        )
      );
      const [{ data: awsData }, { data: ovhData }] = await Promise.all(apis);
      setLoading(false);
      if (awsData.length && ovhData.length) return;
      if (ovhData.length) return selectDomain(CONFIGS.OVH_API_URL);
      // default case when the user is not recognized by OVH or by both platforms
      selectDomain(CONFIGS.API_URL);
    })();
  }, [context?.loginHint]);

  return {
    domain,
    loading,
    selectDomain,
  };
};

export default useDomainSelector;
