import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../rootReducer";
import Auth from "../../core/models/Auth";
import { AuthActionTypes, UPDATE_USERNAME, UPDATE_USER } from "./types";
import jwt from "jwt-simple";

export const updateUsername = (username: string): AuthActionTypes => {
  return {
    type: UPDATE_USERNAME,
    payload: { username },
  };
};

export const updateUser =
  (user: Auth) =>
  (
    dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes>,
    getState: () => RootState
  ) => {
    // token payload
    const payload = {
      iss: "Riseup microsoft teams",
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor(Date.now() / 1000 + 1296000),
      aud: getState().config.front,
      sub: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      username: user.username,
    };
    // encode token
    const token = jwt.encode(payload, getState().config.jwt);
    dispatch({
      type: UPDATE_USER,
      payload: { ...user, token },
    });
  };
