import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import configReducer from "./config/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
