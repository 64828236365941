import {
  formatRange,
  getLocalStorage,
  sendAsyncRequest,
} from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";
import Pagination from "../core/models/Pagination";
import Training from "../core/models/Training";
import GroupTraining from "../core/models/GroupTraining";
import TrainingModel from "../core/models/Training";

/**
 * fetch trainings by user ID
 *
 * @param userId
 * @param page
 * @param perPage
 * @param search
 * @param filter
 * @param order
 * @returns
 */
const fetchByUserId = (
  userId: number,
  page: number,
  perPage?: number,
  search?: string,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string }
): Promise<{ data: Training[]; pagination: Pagination }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  console.log(authorization);
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  // search
  if (search !== undefined) params = { ...params, search };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    URLS.USER_TRAININGS.replace("{id}", `${userId}`),
    authorization,
    params
  ).then((response) => {
    return {
      data: response.data,
      pagination: formatRange(response.headers["content-range"]),
    };
  });
};

export const fetchLastCoursesByUserId = (
  userId: number
): Promise<TrainingModel[]> => {
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  return sendAsyncRequest(
    "get",
    URLS.USER_DASHBOARD_TRAININGS.replace("{id}", `${userId}`),
    authorization,
    {
      desc: "trainingsubscription@subscribedate",
      range: "0-3",
    }
  ).then((response) => response.data);
};

/**
 * fetch trainings by user ID
 *
 * @param groupId
 * @param page
 * @param perPage
 * @param search
 * @param filter
 * @param order
 * @returns
 */
const fetchByGroupId = (
  groupId: number,
  page: number,
  perPage?: number,
  search?: string,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string }
): Promise<{ data: GroupTraining[]; pagination: Pagination }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  // search
  if (search !== undefined) params = { ...params, search };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    URLS.GROUP_TRAININGS.replace("{id}", `${groupId}`),
    authorization,
    params
  ).then((response) => ({
    data: response.data,
    pagination: formatRange(response.headers["content-range"]),
  }));
};

export const trainings = {
  fetchByUserId,
  fetchByGroupId,
  fetchLastCoursesByUserId,
};
