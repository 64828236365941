const CONSTANTS = {
  RISE_UP: "rise_up",
  WELCOME_MESSAGE: "welcome_message",
  TOKEN: "token",
  MICROSOFT_GRAPH_TOKEN: "microsoft_graph_token",
  PLATFORM: "platform",
  PHYSYICAL_MODULES: "physical",
  ONLINE_MODULES: "online",
  COOKIES_DURATION: 30 * 60 * 1000, // 30min
  COOKIES_LONG_DURATION: 60 * 60 * 24 * 365 * 10, // 10y
  SEARCH_DELAY: 500, // 500ms
  VALIDATED: "validated",
  DOTS: "DOTS",
  PER_PAGE: 10,
  DOCUMENT_TYPES: {
    trainingContract: "trainingContract",
    trainingAttestation: "trainingAttestation",
    sessionConvocation: "sessionConvocation",
    attendanceAttestation: "attendanceAttestation",
    trainingProgram: "trainingProgram",
    attendanceSheet: "attendanceSheet",
    pathProgram: "pathProgram",
    objectiveAttestation: "objectiveAttestation",
    sessionGroupConvocation: "sessionGroupConvocation",
    pathDoneAttestation: "pathDoneAttestation",
  },
  COOKIES: {
    USERS: "users",
    COMPANIES: "companies",
    GROUP_USERS: "group_users",
    TRAININGS: "trainings",
    OBJECTIVES: "objectives",
    MODULES: "modules",
    MODULES_BY_ID: "modules_by_id",
    GROUPS: "groups",
    TRAINING_SUBSCRIPTIONS: "training_subscriptions",
    TRAINING_PATHS: "training_paths",
    OBJECTIVE_SUBSCRIPTIONS: "objective_subscriptions",
    TOKEN: "token",
    SESSIONS: "sessions",
    SESSION_SUBSCRIPTIONS: "session_subscriptions",
    OBJECTIVE_LEVELS: "objective_levels",
    OBJECTIVE_LEVEL_USER: "objective_level_user",
    DOCUMENTS: "documents",
    CONFIGS: "configs",
    DOMAIN: "domain",
  },
  TEAMS_USER_EVENTS: {
    TEAMS_TRAININGS_PAGE: "TEAMS_TRAININGS_PAGE",
    TEAMS_DOCUMENTS_PAGE: "TEAMS_DOCUMENTS_PAGE",
    TEAMS_GROUPS_PAGE: "TEAMS_GROUPS_PAGE",
    TEAMS_OPEN_COURSE: "TEAMS_OPEN_COURSE",
    TEAMS_OPEN_VERSION_WIDGET: "TEAMS_OPEN_VERSION_WIDGET",
    TEAMS_DOCUMENT_DOWNLOAD: "TEAMS_DOCUMENT_DOWNLOAD",
  },
  CUSTOM_EVENTS: {
    TEAMS_APP_CLOSE_FRAME: "TEAMS_APP_CLOSE_FRAME",
  },
  SESSION_TYPES: {
    FACE_TO_FACE: "face-to-face",
    VIRTUAL: "virtual",
  },
  EVALUATION_TYPES: {
    TRAINING_EVALUATION: "trainingevaluation",
  },
  GLOBAL_CLASSES: {
    DEFAULT: "default",
    DARK: "dark",
    CONTRAST: "contrast",
  },
  FALLBACK_TIMEZONE: "Europe/Paris",
  ERROR: {
    MESSAGES: {
      DEFAULT: "Something went wrong",
    },
  },
  COURSE_PRICE_RANGE: {
    MIN: 0,
    MAX: 101,
  },
  TRAINING_TYPES: {
    BLENDED: "blended",
    ELEARNING: "elearning",
    PHYSICAL: "physical",
    REMOTE: "remote",
    PHYSICAL_REMOTE: "physical_remote",
  },
  DEBOUNCE_PERIOD: 500,
};

export default CONSTANTS;
