import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const AdditionalInfoContainer = styled(Flex)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const AdditionalInfoTitle = styled(Text)({
  fontWeight: "700",
  fontSize: "0.75rem",
  lineHeight: "1.25rem",
  width: "100%",
  textAlign: "start",
  marginBottom: "0.5rem",
  textTransform: "uppercase",
  color: "var(--card-sub-title)",
});
export const AdditionalInfo = styled(Flex)({
  backgroundColor: "var(--color-gray-shade)",
  borderRadius: "0.5rem",
  padding: ".75rem",
  display: "flex",
  flexDirection: "column",
  minHeight: "4rem",
  justifyContent: "center",
  color: "var(--color-black)",
});
export const DataContainer = styled(Flex)({
  justifyContent: "space-between",
});
export const StepIconContainer = styled("div")({
  height: "2rem",
  width: "2rem",
  marginRight: "1rem",
});
